import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Layout from '../components/layout'
import Social from '../components/social'
import Contact from '../components/contact'
import SEO from '../components/seo'

import * as styles from './blogpost.module.css'

export default function Template({ data, location }) {
  const { mdx } = data
  const { frontmatter, body } = mdx

  return (
    <Layout location={`/${mdx.slug}`}>
      <SEO title={frontmatter.title} />
      <main className={styles.main}>
        <div className={styles.date}>{frontmatter.date}</div>
        <h2 className={styles.title}>{frontmatter.title}</h2>
        <div>
          <MDXRenderer>{body}</MDXRenderer>
        </div>
        <Social title={frontmatter.title} loc={location.href} />
        <Contact />
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      body
      slug
      frontmatter {
        tags
        title
        date
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 960, maxHeight: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
