import React from 'react'

import imgLinkedIn from '../images/social/linkedin.svg'
import imgFB from '../images/social/facebook.svg'
import imgTwitter from '../images/social/twitter.svg'

import styles from './social.module.css'

const Social = ({ title, loc }) => {
  return (
    <div>
      <h3>Podziel się tym postem</h3>
      <div className={styles.social}>
        <a href={`https://facebook.com/sharer.php?u=${loc}`}>
          <div className={styles.icon}>
            <img src={imgFB} alt="Facebook" />
          </div>
        </a>
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${loc}&title=${title}`}
        >
          <div className={styles.icon}>
            <img src={imgLinkedIn} alt="Linkedin" />
          </div>
        </a>
        <a href={`https://twitter.com/intent/tweet?text=${title}%20${loc}`}>
          <div className={styles.icon}>
            <img src={imgTwitter} alt="Twitter" />
          </div>
        </a>
      </div>
      <div className={styles.clear} />
    </div>
  )
}

export default Social
